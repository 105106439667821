@import url('https://fonts.googleapis.com/css2?family=Suranna&display=swap');
 @font-face {
     font-family: 'King';
     font-style: normal;
     src: local('King'), local('the-king-and-queen-font'), url('../../common/header/the_King__26_Queen_font.ttf') format('truetype'), 
}
 .text-logo {
     color: black ;
     font-size: 20px;
     font-family: "King";
     padding: 28px 0 0 13px;
}
 .contepup{
    width: 29vh;
    height: 29vh;
    max-width: 320px;
    max-height: 320px;
    min-width: 215px;
    min-height: 215px;
    position: relative;
    margin: 2% auto 0;
    z-index: 15;
    overflow: visible;
}
 .conterota {
     height: 92px;
     margin-top: -5%;
}
 .conterota h3{
    font-family: "suranna"!important;
    line-height: .8;
    margin: 54px 0 0;
    font-size: 36px;
    font-weight: 500;
}
 #pup1{
     width: 45%;
     height: 45%;
     position: absolute;
     z-index: 7;
     top: 30%;
     right: 30%;
}
 #pup2{
    width: 75%;
     height: 75%;
     position: absolute;
     z-index: 6;
     top: 15%;
     right: 15%;
}
 .poi {
     stroke: rgba(0,0,0,1) !important;
     fill: rgba(255, 255, 255, 0) !important;
}
 #pup3{
     height: 90%;
     position: absolute;
     z-index: 5;
     top: 7.5%;
     right: 7.5%;
}
 #fondo1 {
     width: 99%;
     height: 2000px;
     position: absolute;
     padding: 3% .5% 0% .5%;
}
 #fondo1 svg{
     padding: 0% 0 0 0;
     transition: all ease .3s;
}
 .fondo1 {
     stroke-width: 1.3;
    ;
}
.invert{
    color: #ffffff;
      position: fixed;
      z-index: 18;

}
.section-service-content{
    height: 100vh;
    display: flex;
    align-items: center;
}
 .services-section{
     padding: 0;
     padding: 150px 0 150px 0;
}
 .services{
     width: 35vw;
     height: 35vw;
     margin: 0 auto;
     position: relative;
}
 .services article figure.fonser{
     width: 100%;
}
 .fullscreen {
     min-height: 100vh;
     width: 95% !important;
     top: 21px;
}

 .back-item-s{
     position: absolute;
     width: 100%;
     left: 0;
}

 .logo {
     height: 75px;
     width: 75px;
     padding: 0px 0 0 0;
}
 .services .grid {
    cursor: pointer;
     position: relative;
     height: 38%;
     width: 38%;
}
 #hyper-modal-wrapper_component_id{
     z-index: 100;
}
 .services .grid:nth-child(1) {
     top: 0%;
     left: 31%;
}
 .services .grid:nth-child(2) {
     top: -7%;
     left: 0%;
     float: left;
}
 .services .grid:nth-child(3) {
     top: -7%;
     right: 0%;
     float: right;
     margin: 0 0;
}
 .services .grid:nth-child(4) {
     bottom: 0px;
     top: 24%;
     margin: 0 auto;
}
 .services .grid:nth-child(1) .text-item {
     bottom: -111%;
     width: 100%;
     min-width: 200px;
     left: -6%;
     z-index: 10;
     position: relative;
}
 .services .grid:nth-child(2) .text-item {
     bottom: -29%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: 77%;
     z-index: 10;
     position: relative;
}
 .services .grid:nth-child(3) .text-item {
     bottom: -29%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: -86%;
     z-index: 10;
     position: relative;
}
 .services .grid:nth-child(4) .text-item {
     top: -52%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: -4%;
     z-index: 10;
}
/* Common style */
 .services .grid figure {
     position: relative;
     margin: 0 auto;
     width: 100%;
     height: 100%;
     text-align: center;
     cursor: pointer;
     color: #fff;
     text-transform: uppercase;
     font-size: 1.25em;
}
 .services .grid figure figcaption {
     padding: 2em;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
}
 .services .grid figure figcaption::before, .services .grid figure figcaption::after {
     pointer-events: none;
}
 .services .grid figure figcaption, .services .grid figure figcaption > a {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
 .services .grid figure figcaption > a {
     z-index: 1000;
     text-indent: 200%;
     white-space: nowrap;
     font-size: 0;
     opacity: 0;
}
 .content-logo {
     width: 100%;
     height: 100%;
     transition: linear 1s;
}
 .services .grid figure h2 {
     word-spacing: -0.15em;
     font-weight: 300;
}
 .services .grid figure h2 span {
     font-weight: 800;
     font-size: 17px;
}
 .services .grid figure h2, .services .grid figure p {
     margin: 0;
}
 .services .grid figure p {
     letter-spacing: 1px;
     font-size: 68.5%;
     color: black;
}
/* Individual effects */
 .indicator{
     transform-origin: 0px 0px !important;
     transition: linear .1s;
}
 .indicatorr{
     transition: linear .1s;
}
 .indicatorrr{
     transition: linear .1s;
}
/*---------------*/
/***** Layla *****/
/*---------------*/
 .services figure.effect-layla {
}
 .services figure.effect-layla figcaption {
     padding: 1.3em;
     -webkit-transform:rotate(45deg);
     transform:rotate(45deg);
}
 .services figure.effect-layla figcaption::before, .services figure.effect-layla figcaption::after {
     position: absolute;
     content: '';
     opacity: 0;
}
 .back-item-sf{
     border-radius: 50%;
     width: 100%;
     height: 100%;
     border: solid 5px rgba(94, 94 ,94, 0.4);
     background-color: rgba(0, 0, 0, 0);
}
 .services figure.effect-layla figcaption::before {
     top: 20px;
     right: 10px;
     bottom: 20px;
     left: 10px;
     border-top: 1px solid rgba(255,255,255,.6);
     border-bottom: 1px solid rgba(255,255,255,.6);
     -webkit-transform: scale(0,1);
     transform: scale(0,1);
     -webkit-transform-origin: 0 0;
     transform-origin: 0 0;
}
 .services figure.effect-layla figcaption::after {
     top: 10px;
     right: 20px;
     bottom: 10px;
     left: 20px;
     border-right: 1px solid rgba(255,255,255,.6);
     border-left: 1px solid rgba(255,255,255,.6);
     -webkit-transform: scale(1,0);
     transform: scale(1,0);
     -webkit-transform-origin: 100% 0;
     transform-origin: 100% 0;
}
 .services .icon1{
     width: 25%;
     left: 37.5%;
     transition: 0.35s;
     position: absolute;
     z-index: 18;
}
 .services .cls-ab{
     fill: none;
     opacity: .81;
     stroke: #fff;
     stroke-miterlimit: 10;
}
 .st0{
    fill:none;
    stroke:rgba(0, 0, 0, .7);
    stroke-width:1.7;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}
 .st0[data-isOn="true"] {
     stroke:rgba(255,255,255, 1.0);
     fill: #ffffff2e;
}
 .services figure.effect-layla h2 {
     padding-top: 30%;
     font-size: 1.1em;
     font-family: "geo";
     color: rgba(234, 190, 63, .9);
     -webkit-transition: -webkit-transform 0.35s;
     transition: transform 0.35s;
}
 .services figure.effect-layla p {
     padding: 0.5em 2em;
     text-transform: none;
     opacity: 0;
     display: none;
     -webkit-transform: translate3d(0,-10px,0);
     transform: translate3d(0,-10px,0);
}
 .services figure.effect-layla h2 {
     -webkit-transform: translate3d(0,-30px,0);
     transform: translate3d(0,-30px,0);
}
 .services figure.effect-layla figcaption::before, .services figure.effect-layla figcaption::after, .services figure.effect-layla p {
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
}
 .services figure.effect-layla:hover figcaption::before, .services figure.effect-layla:hover figcaption::after {
     opacity: 1;
     -webkit-transform: scale(1);
     transform: scale(1);
}
 .services figure.effect-layla:hover h2, .services figure.effect-layla:hover p {
     opacity: 1;
     -webkit-transform: translate3d(0,-124px,0);
     transform: translate3d(0,-124px,0);
}
 .services figure.effect-layla:hover img.icon1{
     -webkit-transform: translate3d(0,50px,0);
     transform: translate3d(0,50px,0);
}
 .services figure.effect-layla figcaption{
     overflow: hidden;
     opacity: 0;
}
 .services figure.effect-layla:hover figcaption{
     opacity: 1;
     overflow: hidden;
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
     background-color: rgba(0,0,0,1);
}
 .services figure.effect-layla:hover .st1 ,.services figure.effect-layla:hover .st0{
     stroke: #ffffff;
}
 figcaption .rn-gradient-circle {
     position: fixed;
     left: 2px;
     top: -100px;
     right: auto;
     bottom: auto;
     z-index: -1;
     width: 500px;
     height: 500px;
     border-radius: 1000px;
}
 .services figure.effect-layla:hover figcaption::after, .services figure.effect-layla:hover h2, .services figure.effect-layla:hover p, .services figure.effect-layla:hover img {
     -webkit-transition-delay: 0.15s;
     transition-delay: 0.15s;
}
 .vh70{
     height: 75vh;
     display: flex;
     align-items: center;
     padding: 10% 0 10% 0;
}
 .vh80{
     height: 80vh;
}
 .vh90{
     height: 90vh;
     padding-top: 70px;
}
 .vh100{
     height: 100vh;
}
.mxw{
    max-height: 900px;
}
.mxw .container{
    height: 100%;
}
.mxw .container .row--30{
    height: 100%;
}
.mxw .container .row--30 .col-lg-7 {
    height: 100%;
}


 .serviceslink{
     position: absolute;
     margin-top: 35px;
     left: 48%;
}
 .serviceslink .botonini{
     width: 50px;
}
 .reloj{
     width: 31%;
     position: absolute;
     z-index: 2;
     margin:4% 0 0 0%;
}
 .triangle-down {
    margin: 100px 0 250px 0;
     width: 100vw;
     height: 0;
     border-left: 50vw solid transparent;
     border-right: 50vw solid transparent;
     border-top: 100px solid rgba(255,255,255,1);
     margin-top: -2px ;
}
 @media screen and (max-width: 50em) {
     .content {
         padding: 0 10px;
         text-align: center;
    }
     .services .grid figure {
         display: inline-block;
         float: none;
         margin: 10px auto;
         width: 100%;
    }
}
 .fonser{
     width: 100%;
     height: width;
}
 .abajo1 {
     bottom: 0px;
     position: absolute;
     width: 66%;
     height: 28%;
     right: 17%;
}
 .arriva1 {
     top: -33px;
     position: absolute;
     width: 66%;
     height: 35%;
     right: 17%;
}
 .contemenu .grid {
     width: 40%;
     position: absolute;
     text-align: center;
}
 .contemenu h2{
     font-family: "geo";
     color:rgba(234, 190, 63, .9);
     margin: 10px auto;
}
 div.contemenu{
     width: 27vw;
     height: 27vw;
     min-width: 270px;
     margin: 37px auto 0px;
     position: relative;
}
 div.contemenu div.grid:nth-child(1){
     top: 0;
     left: 30%;
}
 .fonco1{
     width: 100%;
}
 div.contemenu div.grid:nth-child(2){
     top: 30%;
     left: 0%;
}
 div.contemenu div.grid:nth-child(3){
     top: 30%;
     right: 0%;
}
 div.contemenu div.grid:nth-child(4){
     top: 36%;
     right:30%;
}
 div.contemenu div.grid:nth-child(5){
     top: 0%;
     left: -30%;
}
 div.contemenu div.grid:nth-child(6){
     top: 0%;
     right:-30%;
}
 div.contemenu div.grid:nth-child(7){
     top: 0;
     left: 30%;
}
/* Common style */
 .contemenu .grid figure {
     position: relative;
     float: left;
     width: 100%;
     overflow: hidden;
     max-width: 360px;
     max-height: 360px;
     text-align: center;
     cursor: pointer;
     transform: rotate(-45deg);
     -webkit-transform: rotate(-45deg);
     -moz-transform: rotate(-45deg);
     -o-transform: rotate(-45deg);
}
 .contemenu .grid figure img {
     position: relative;
     display: block;
     min-height: 100%;
     max-width: 100%;
     opacity: 1;
}
 .contemenu .grid figure figcaption {
     padding: 2em;
     color: #fff;
     text-transform: uppercase;
     font-size: 1.25em;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
}
 .contemenu .grid figure figcaption::before, .contemenu .grid figure figcaption::after {
     pointer-events: none;
}
 .contemenu .grid figure figcaption, .contemenu .grid figure figcaption > a {
     position: absolute;
     top: 29px;
     left: -29px;
     width: 100%;
     height: 100%;
}
/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
 .contemenu .grid figure figcaption > a {
     z-index: 1000;
     text-indent: 200%;
     white-space: nowrap;
     font-size: 0;
     opacity: 0;
}
 .contemenu .grid figure h2 {
     word-spacing: -0.15em;
     font-weight: 300;
}
 .contemenu .grid figure h2 span {
     font-weight: 800;
}
 .contemenu .grid figure h2, .contemenu .grid figure p {
     margin: 0;
}
 .contemenu .grid figure p {
     letter-spacing: 1px;
     font-size: 68.5%;
}
/* Individual effects */
/*---------------*/
/***** Romeo *****/
/*---------------*/
 .contemenu figure.effect-romeo {
     background-color: rgba(255,255,255,1);
     -webkit-perspective: 1000px;
     perspective: 1000px;
}
 .contemenu figure.effect-romeo img {
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
     -webkit-transform: translate3d(0,0,300px);
     transform: translate3d(0,0,300px);
     opacity: 1;
}
 .contemenu figure.effect-romeo:hover img {
     opacity: .2;
     -webkit-transform: translate3d(0,0,0);
     transform: translate3d(0,0,0);
}
 .contemenu figure.effect-romeo figcaption::before, .contemenu figure.effect-romeo figcaption::after {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 150%;
     height: 1px;
     background: rgba(234, 190, 63, .9);
     content: '';
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
     -webkit-transform: translate3d(-50%,-50%,0);
     transform: translate3d(-50%,-50%,0);
     opacity: .2;
}
 .contemenu figure.effect-romeo:hover figcaption::before {
     opacity: 0.6;
     -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
     transform: translate3d(-50%,-50%,0) rotate(45deg);
}
 .contemenu figure.effect-romeo:hover figcaption::after {
     opacity: 0.5;
     -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg);
     transform: translate3d(-50%,-50%,0) rotate(-45deg);
}
 .contemenu figure.effect-romeo h2, .contemenu figure.effect-romeo p {
     position: absolute;
     top: 50%;
     left: 0;
     width: 100%;
     opacity: 0.2;
     -webkit-transition: -webkit-transform 0.35s;
     transition: transform 0.35s;
}
 .contemenu figure.effect-romeo h2 {
     -webkit-transform: translate3d(0,-50%,0) translate3d(0,-51%,0);
     transform: translate3d(0,-50%,0) translate3d(0,-51%,0);
}
 .contemenu figure.effect-romeo p {
     padding: 0.25em 2em;
     -webkit-transform: translate3d(0,-50%,0) translate3d(0,66%,0);
     transform: translate3d(0,-50%,0) translate3d(0,66%,0);
}
 .contemenu figure.effect-romeo:hover h2 {
     -webkit-transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
     transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
     opacity: 1;
}
 .contemenu figure.effect-romeo:hover p {
     -webkit-transform: translate3d(0,-50%,0) translate3d(0,100%,0);
     transform: translate3d(0,-50%,0) translate3d(0,100%,0);
}
/* reloj*/
 .clock {
     width: 100%;
     top: 0%;
     left: 0px;
     position: absolute;
     height: calc(100% / 2.5);
}
 .dot {
     width: 14px;
     height: 14px;
     border-radius: 50%;
     background: #ccc;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     margin: auto;
     position: absolute;
     z-index: 10;
     box-shadow: 0 2px 4px -1px black;
}
 .hour-hand {
     position: absolute;
     z-index: 5;
     width: 9.5%;
     top: 34.2%;
     transform-origin: 50% 90%;
     left: 45%;
     margin-left: -2px;
     border-top-left-radius: 50%;
     border-top-right-radius: 50%;
}
 .hour-hand img{
     width: 100%;
     margin-bottom:-37%;
}
 .minute-hand {
     position: absolute;
     z-index: 6;
     width: 5%;
     top: 31.5%;
     left: 47.2%;
     border-top-left-radius: 50%;
     border-top-right-radius: 50%;
     transform-origin: 50% 92.9%;
}
 .minute-hand img{
     width: 100%;
     margin-bottom:-50%;
}
 .second-hand {
     position: absolute;
     z-index: 7;
     width: 4.55%;
     top: 36.8%;
     left: 47.5%;
     margin-left: -1px;
     border-top-left-radius: 50%;
     border-top-right-radius: 50%;
     transform-origin: 50% 72%;
}
 .second-hand img{
     width: 100%;
     margin-bottom:-300%;
}
 .diallines {
     position: absolute;
     z-index: 2;
     width: 2px;
     height: 15px;
     background: #666;
     left: 50%;
     margin-left: -1px;
     transform-origin: 50% 150px;
}
 .diallines:nth-of-type(5n) {
     position: absolute;
     z-index: 2;
     width: 4px;
     height: 25px;
     background: #666;
     left: 50%;
     margin-left: -1px;
     transform-origin: 50% 150px;
}
 .date {
     top: 80px;
}
 .day {
     top: 200px;
}
 .hyperCloseIconWrapper___ujoQX {
    position: fixed !important;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    border-radius: 50%;
    top: 38px !important;
    opacity: .8 ;
    right: 5%!important;
}
 .pages header{
     display: none;
     background: rgba(255,255,255,1);
}
 p {
     background: rgba(255,255,255,.03);
}
 .fullscreen .rn-gradient-circle.theme-pink {
     left: auto;
     right: 35px;
}
 .fullscreen .rn-gradient-circle {
     position: fixed;
     left: 35px;
}
 .service-area.rn-section-gapTop{
     padding-bottom: 70px;
}
 .infinite {
     -webkit-animation-duration: 2s;
     animation-duration: 2s;
     -webkit-animation-fill-mode: both;
     animation-fill-mode: both;
     animation-delay: 2s;
     -webkit-animation-iteration-count: infinite;
     animation-iteration-count: infinite;
     animation-delay: 2s;
     cursor:pointer;
}
 .pup3 {
     position: relative;
     width: 100%;
}
 .pup3 svg {
     overflow: visible !important;
}
 .pend{
     position: absolute;
     width: 100%;
     height: 100%;
}
body::-webkit-scrollbar , .hyperModalContentWrapper___1o7Ug.fullscreen::-webkit-scrollbar{
  width: 10px;
}

body::-webkit-scrollbar-track , .hyperModalContentWrapper___1o7Ug.fullscreen::-webkit-scrollbar-track{
  background-color: #e4e4e4;
}

body::-webkit-scrollbar-thumb , .hyperModalContentWrapper___1o7Ug.fullscreen::-webkit-scrollbar-thumb{
background: rgba(0, 0, 0, .5);  
border-radius: 100px;
  opacity: .7;
}
 .services .section-title .subtitle {
     width: 100%;
     padding: 7px 6px !important;
     background-color: rgba(255, 255, 255, .95);
     -webkit-box-shadow: 1px 1px 7px 2px rgba(0,0,0,0.26);
     -moz-box-shadow: 1px 1px 7px 2px rgba(0,0,0,0.26);
     box-shadow: 1px 1px 7px 2px rgba(0,0,0,0.26);
}
 .rn-card .inner .content .title {
     min-height: 60px;
}
 .elementor-tab-title {
     display: none !important;
}
 @media (min-width: 500px){
     .elementor-tab-title {
         display: block !important;
    }
}
 .systemsolar {
     z-index: 0;
     width: 60vw;
     height: 60vw;
     position: absolute;
     left: 19.8vw;
}
 .sun{
     position: absolute;
     width: 12%;
     height: 12%;
     left: 44%;
     top: 44%;
}
 @media screen and (max-width: 500px) {
     .vh70{
         height: auto;
         display: flex;
         align-items: center;
    }
     .vh80{
         height: auto;
    }
     .services {
         width: 99vw;
         height: 99vw;
    }
     .systemsolar {
         z-index: 0;
         width: 185vw;
         height: 185vw;
         position: absolute;
         left: -42.2vw;
         margin-top: 0.5vw;
    }
     .vh90{
         height: auto;
    }
     .vh100{
         height: auto;
         min-height: 650px;
    }
     .rn-card .inner .content .title {
         font-size: 13px !important;
    }
     .rn-meta-list li {
         display: none;
    }
     div.contemenu {
         height: 82vw;
    }
     .serviceslink {
         left: 44%;
    }
     .services .grid figure h2 span {
         font-weight: 800;
         font-size: 13px;
    }
     .conterota h3{
            font-family: "suranna" !important;
            line-height: .8;
            margin: 29px 0 0;
            font-size: 28px;
            font-weight: 500;
    }
     .abajo1 {
         bottom: 0px;
         position: absolute;
         width: 39%;
         height: 41%;
         right: 30%;
    }
     .subme {
         font-size: 17px !important;
    }
     #fondo1 {
        width: 99%;
        position: absolute;
        margin: 13% .5% 0;
        bottom: -13%;
        height: auto;
    }
}
 .rn-service-area.relati{
     position: relative;
}
 .text-item{
     bottom: -37%;
     width: 90%;
     left: 5%;
     position: absolute;
}
 .cls-1 {
     stroke: #3a3a3a;
     stroke-linecap: round;
     stroke-linejoin: round;
}
 .cls-1, .cls-2, .cls-3, .cls-4 {
     fill: none;
}
 .cls-1, .cls-3, .cls-4 {
     stroke-width: .5px;
}
 .cls-2 {
     stroke: #bababa;
}
 .cls-2, .cls-3, .cls-4 {
     stroke-miterlimit: 10;
}
 .cls-3 {
     stroke: #8e8e8e;
}
 .cls-4 {
     stroke: #d8d8d8;
}
 .cls-5 {
     opacity: .73;
}
 .solar{
     overflow: visible !important;
     position: absolute;
}
 .pl1{
     transform-origin: 0% 11.8%;
     translate: 50% 38.2%;
     -webkit-animation:spin 10s linear infinite;
     -moz-animation:spin 10s linear infinite;
     animation:spin 10s linear infinite;
}
 .pl2{
     transform-origin: 0% 17.2%;
     translate: 50% 32.8%;
     -webkit-animation:spin 15s linear infinite;
     -moz-animation:spin 15s linear infinite;
     animation:spin 15s linear infinite;
}
 .pl3{
     transform-origin: 0% 25%;
     translate: 50% 25%;
     -webkit-animation:spin 17s linear infinite;
     -moz-animation:spin 17s linear infinite;
     animation:spin 17s linear infinite;
}
 .pl4{
     transform-origin: 0% 30.8%;
     translate: 50% 19.2%;
     -webkit-animation:spin 27s linear infinite;
     -moz-animation:spin 27s linear infinite;
}
 .pl5{
     transform-origin: 0% 38.2%;
     translate: 50% 11.8%;
     animation: spins 5s linear infinite;
     -webkit-animation:spin 15s linear infinite;
     -moz-animation:spin 15s linear infinite;
}
 .pl6{
     transform-origin: 0% 43.8%;
     translate: 50% 6.2%;
     -webkit-animation:spin 32s linear infinite;
     -moz-animation:spin 32s linear infinite;
     animation:spin 32s linear infinite;
}
 .pl7{
     transform-origin: 0% 48%;
     translate: 50% 2%;
     -webkit-animation:spin 35s linear infinite;
     -moz-animation:spin 35s linear infinite;
     animation:spin 35s linear infinite;
}
 .pl8{
     transform-origin: 0% 52%;
     translate: 50% -2%;
     -webkit-animation:spin 25s linear infinite;
     -moz-animation:spin 25s linear infinite;
     animation:spin 25s linear infinite;
}
 @keyframes spin{
     100%{
         transform: rotate(360deg);
         overflow: visible;
    }
}
 .pecado-pilar {
     height: 124%;
     margin-left: 44.5%;
     position: absolute;
     margin-top: -16.5%;
}
 .pecado-pilar1 {
     width: 8.5%;
     margin-top: -23.5%;
     margin-left: 45.75%;
}
 .pecado-pilar2 {
     width: 8.5%;
    margin-top: -6.5%;
    margin-left: 45.75%;
}
 .pecado-pilar3 {
     width: 11%;
}
 .pecado-pilar-1 {
     fill: #29601d;
     opacity: .68;
     stroke: #000;
}
 .pecado-pilar-1, .pecado-pilar-2, .pecado-pilar-3 {
     stroke-miterlimit: 10;
}
 .pecado-pilar-2 {
     stroke: #727272;
     stroke-width: .5px;
}
 .pecado-pilar-2, .pecado-pilar-3 {
     fill: none;
}
 .pecado-pilar-3 {
     stroke: #8c8c8c45;
     stroke-width: 2px;
}
 .slick-dots,.slick-next,.slick-prev{
    position:absolute;
    display:block;
    padding:0
}
.slick-dots li button:before,.slick-next:before,.slick-prev:before{
    font-family:slick;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.slick-loading .slick-list{
    background:url(../images/ajax-loader.gif) center center no-repeat #fff
}
@font-face{
    font-family:slick;
    font-weight:400;
    font-style:normal;
    src:url(../fonts/slick.eot);
     src:url(../fonts/slick.eot?#iefix) format('embedded-opentype'),url(../fonts/slick.woff) format('woff'),url(../fonts/slick.ttf) format('truetype'),url(../fonts/slick.svg#slick) format('svg')
}
.slick-next,.slick-prev{
    font-size:0;
    line-height:0;
    top:50%;
    width:20px;
    height:20px;
    -webkit-transform:translate(0,-50%);
    -ms-transform:translate(0,-50%);
    transform:translate(0,-50%);
    cursor:pointer;
    color:transparent;
    border:none;
    outline:0;
    background:0 0
}
.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{
    color:transparent;
    outline:0;
    background:0 0
}
.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{
    opacity:1
}
.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{
    opacity:.25
}
.slick-next:before,.slick-prev:before{
    font-size:20px;
    line-height:1;
    opacity:.75;
    color:#fff
}
.slick-prev{
    left:-25px
}
[dir=rtl] .slick-prev{
    right:-25px;
    left:auto
}
.slick-prev:before{
    content:'←'
}
.slick-next:before,[dir=rtl] .slick-prev:before{
    content:'→'
}
.slick-next{
    right:-25px
}
[dir=rtl] .slick-next{
    right:auto;
    left:-25px
}
[dir=rtl] .slick-next:before{
    content:'←'
}
.slick-dotted.slick-slider{
    margin-bottom:30px
}
.slick-dots{
    bottom:-25px;
    width:100%;
    margin:0;
    list-style:none;
    text-align:center
}
.slick-dots li{
    position:relative;
    display:inline-block;
    width:20px;
    height:20px;
    margin:0 5px;
    padding:0;
    cursor:pointer
}
.slick-dots li button{
    font-size:0;
    line-height:0;
    display:block;
    width:20px;
    height:20px;
    padding:5px;
    cursor:pointer;
    color:transparent;
    border:0;
    outline:0;
    background:0 0
}
.slick-dots li button:focus,.slick-dots li button:hover{
    outline:0
}
.slick-dots li button:focus:before,.slick-dots li button:hover:before{
    opacity:1
}
.slick-dots li button:before{
    font-size:6px;
    line-height:20px;
    position:absolute;
    top:0;
    left:0;
    width:20px;
    height:20px;
    content:'•';
    text-align:center;
    opacity:.25;
    color:#000
}
.slick-dots li.slick-active button:before{
    opacity:.75;
    color:#000
}
.box {
  width: 100%;
  height: auto;
  margin: 0;
  position: fixed;
  overflow: scroll;
  top: 1px;
  z-index: 100000000;
}
.dnk{
    display: none;
}
.fgr{
    position: relative;
    z-index: 1000000000000;
}

.boton1 {
  -webkit-appearance: button;
  border: none;
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 150px;
  position: relative;
  z-index: 999999999999;
}
#hhh {
    width: 400px;
  height: 200px;
  top: 00px;
  padding:100px 0 0 100px;
position: fixed;    
  z-index: 999999999999999999;
}
#scene{

    
   
  z-index: 999999999999999999;

}
/* SECTION PORFOLIO */

.sacrilege{
    width: 60vw;
    height: 60vw;
    margin: 0 0 0 0;
    position: absolute;
    left: 20vw;
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    border-radius: 50%;

}
.sacrelo{
    display: inline-block;
    position: relative;
    width: 100%;
}
.sacrelt{
    display: inline-block;
    position: absolute;
    width: 54%;
    left: 23%;
    top: 22.7%;
}
.sacreltr{
    display: inline-block;
    position: absolute;
    width: 83%;
    left: 8.5%;
    top: 8.5%;
}
.sacreone {
    fill: none;
    opacity: .6;
    stroke: #ffffff;
    stroke-miterlimit: 10;
    stroke-width: 0.7px;
}
.luz {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
}
.bastion {
    fill: none;
    opacity: .6;
    stroke: #fff;
    stroke-width: .5;
    stroke-miterlimit: 10;

}
.portfoliot{
    position: relative;
    width: 100vw;
    margin: 50px 0 100px 0;
    display: flex;
    align-items: center;

}
.content-portfolioi{
    width: 33vw;
    height: 33vw;
    margin: 0% auto;
    position: relative;
    margin-top: 0vw;
}
.portfoliot .st0p {
    fill: rgb(2, 2, 2 );
    stroke: rgb(255, 255, 255 );
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.portfoliot a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
}
.portfoliot .grid {
    cursor: pointer;
     position: relative;
     height: 38%;
     width: 38%;
}
 #hyper-modal-wrapper_component_id{
     z-index: 100;
}
 .portfoliot .grid:nth-child(1) {
     top: 0%;
     left: 31%;
     position: relative;
}
 .portfoliot .grid:nth-child(2) {
     top: -7%;
     left: 0%;
     float: left;
     position: relative;
}
 .portfoliot .grid:nth-child(3) {
     top: -7%;
     right: 0%;
     float: right;
     margin: 0 0;
     position: relative;
}
 .portfoliot .grid:nth-child(4) {
     bottom: 0px;
     top: 24%;
     margin: 0 auto;
     position: relative;
}
 .portfoliot .grid:nth-child(1) .text-item {
     bottom: -111%;
     width: 100%;
     min-width: 200px;
     left: 0%;
     z-index: 10;
     position: relative;
}
 .portfoliot .grid:nth-child(2) .text-item {
     bottom: -29%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: 83%;
     z-index: 10;
     position: relative;
}
 .portfoliot .grid:nth-child(3) .text-item {
     bottom: -29%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: -80%;
     z-index: 10;
     position: relative;
}
 .portfoliot .grid:nth-child(4) .text-item {
     top: -52%;
     width: 100%;
     margin: 0 auto;
     min-width: 200px;
     left: 0%;
     z-index: 10;
}

/* Common style */
 .portfoliot .grid figure {
     position: relative;
     margin: 0 auto;
     width: 100%;
     height: 100%;
     text-align: center;
     cursor: pointer;
     color: #fff;
     text-transform: uppercase;
     font-size: 1.25em;
}
 .portfoliot .grid figure figcaption {
     padding: 2em;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
}
 .portfoliot .grid figure figcaption::before, .portfoliot .grid figure figcaption::after {
     pointer-events: none;
}
 .portfoliot .grid figure figcaption, .portfoliot .grid figure figcaption > a {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
 .portfoliot .grid figure figcaption > a {
     z-index: 1000;
     text-indent: 200%;
     white-space: nowrap;
     font-size: 0;
     opacity: 0;
}
 .content-logo {
     width: 100%;
     height: 100%;
     transition: linear 1s;
}
 .portfoliot .grid figure h2 {
     word-spacing: -0.15em;
     font-weight: 300;
}
 .portfoliot .grid figure h2 span {
     font-weight: 800;
     font-size: 17px;
}
 .portfoliot .grid figure h2, .portfoliot .grid figure p {
     margin: 0;
}
 .portfoliot .grid figure p {
     letter-spacing: 1px;
     font-size: 68.5%;
     color: black;
}
.portfoliot .icon1{
    width: 30%;
    left: 35.5%;
     transition: 0.35s;
     position: absolute;
     z-index: 99;
}

.portfoliot .icones{
    width: 35% !important;
    left: 32.5% !important;
     transition: 0.35s;
     position: absolute;
     z-index: 99;
}
.back-item-port{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}


.back-ports{
   height: 100%; 
   width: 100%;
   position: absolute;
   border-radius: 50%;
}
 .st0p{
    fill:rgba(255, 255, 255, 0.9);
    stroke:rgba(0,0,0, .7);
    stroke-width:1.7;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}
  #canvas1 {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            mix-blend-mode: difference;

        }
        #textInput {
            position: absolute;
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px;
            z-index: 100;
        } #textInputt {
            position: fixed;
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px;
            top: 150px;
            z-index: 150;
            display: none;
        }

        #butonpru{
            width: 1px;
            height: 1px;
            position: fixed;
            margin-top: 150px;
            z-index: 210;
            display: none;
        }

        #styledd {
            padding-top: 100px;
        }

      

        .section-portfolio-content{
            height: 100%;
            display: flex;
            align-items: center;

        }

        #butunpru{
            position: fixed;
            display: none;
        }

        .portfolio-section {
            margin-bottom: 200px;
        }
        #canvas1{
            display: block;
        }
        .nerv{
            width: 80px;
            height: 80px;
            position: fixed;
            top: 54%;
            left: 3.3vw;
            z-index: 4;
            border: 1px solid;
            border-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            mix-blend-mode: difference;
            }
           
         @media screen and (max-width: 500px) {
            #canvas1{
            display: none;
            }
            .nerv {
                width: 80px;
                height: 80px;
                position: fixed;
                top: 85%;
                left: 3.3vw;
                z-index: 4;
                border: 1px solid;
                border-color: rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                mix-blend-mode: difference;
            }
            .sacrilege {
                width: 160vw;
                height: 160vw;
                margin: 0 0px 0 0;
                position: absolute;
                left: -30vw;
                background: radial-gradient(circle, black 0%, rgba(255, 255, 255, 0) 100%);
                border-radius: 50%;
            }
            .portfoliot {
                position: relative;
                width: 100vw;
                margin: 50px 0 100px 0;
                display: flex;
                align-items: center;
            }

            .content-portfolioi {
                width: 99vw;
                height: 99vw;
                margin: 0% auto;
                position: relative;
                margin-top: 0vw;
            }
         }


         

          .nerv .top-one{
            cursor: pointer;
            width: 60%;
            margin: -28% auto 0 auto;
            display: block;
                  }

            .nerv .down-one{
                cursor: pointer;
                width: 60%;
                margin: 0 auto 0 auto;
                display: block;
                position: relative;
                bottom: 0;
                transform: rotate(181deg);
            }
           #textInput{
                position: fixed;
                z-index: 100000000000000000;
            }
            .ncls-2 {
                stroke: #ffffff26;
                fill: transparent;
            }
            .ncls-3 {
                stroke: #ffffff;
                fill: transparent;
            }

            .rn-start-area {
                position: relative;
                z-index: 5;
            }

            #hyper-modal-wrapper_component_id {
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 200;
            }

            .fullscreen {
                min-height: 98vh !important;
                width: 95% !important;
                top: 0px !important;
                overflow-x: hidden;
            }
            .popup-mobile-menu.active {
                visibility: visible;
                opacity: 1;
                mix-blend-mode: exclusion;
            }
            .popup-mobile-menu .inner {
                    
                    background-color: #ffffff !important;
                    width: 370px !important;
                }

                .popup-mobile-menu .mainmenu li + li {
                    border-top: 1px solid rgba(0,0,0,1) !important;
                }

                .popup-mobile-menu.active {
                    background: rgba(255,255,255,.9);
                }

                .popup-mobile-menu .inner .header-top .close-menu .close-button {
                      
                        border-radius: 100%;
                        margin-top: 0.5vh;
                        float: right;
                    }

                    .popup-mobile-menu .mainmenu {
                       
                        padding: 35px 5vh 15px 3.5vw !important;
                    }

                    a.btn-default, button.btn-default {
    padding: 0 10px !important;
   
    font-size: 16px;
    font-weight: 400;
   
    height: 35px !important;
    line-height: 30px !important;
    opacity: .8;
   
}

.activemm #canvas1 , .activemm .nerv , .activemm .services , .activemm .portfoliot , .activemm .footer-st , .activemm .blog-area , .activemm .start{
 opacity: .2 !important;
}

ul li a {
    text-decoration: none;
    color: #444444 !important;
}

.hamberger-button {
   
    color: #c067d7 !important;
    
}
.close-menu {
    width: 100%;
}
.rwt-about-area {
    overflow: hidden;
}
.display-none{
    display: none;
}
.popup-mobile-menu .mainmenu li {
    cursor: pointer;
}
.lasted-section{
        margin: 100px 0 100px 0 !important;
    
}
.content-lasted{
    width: 35vw;
    height: 35vw;
    margin: 100px auto;
    text-align: center;
    align-items: center;
}
.item-lasted {
    position: relative;
    width: 30%;
    height: 30%;
    margin: 0 auto;
    transform: rotate(45deg);
    overflow: hidden;
    
}

.item-lasted:hover {
    z-index: 100;
    
}
.item-lasted .rn-card{
    transform: rotate(-45deg);
    width: 147%;
    height: 147%;
    margin: -23% 0 0 -23%;
    cursor: pointer;
    
}

.item-lasted .rn-card .inner .content:hover{
    
    background: #ffffffc4;
    
}
.item-lasted .rn-card .inner .content:hover .theme-gradient{
    opacity: 1;    
}
.item-lasted .rn-card .inner{
    height: 100%;
    width: 100%;
}
.item-lasted .rn-card .thumbnail{
    height: 100%;
    width: 100%;
}
.item-lasted:nth-child(2){
    display: inline-block;
}
.item-lasted:nth-child(3){
    display: inline-block;
}
.item-lasted:nth-child(4){
    display: inline-block;
}
.item-lasted .rn-card .inner .content {
    
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff36;
    transition: all .5s;
    width: 100%;
    display: flex;
    align-items: center;

}
.item-lasted .rn-card .inner .content .theme-gradient {
    width: 100%;
    font-size: 16px;
    line-height: normal;
    transition: all .5s;
    margin-bottom: 0;
    opacity: 0.2;
    font-weight: 800;
}
.item-lasted .rn-card .inner .thumbnail img {
    height: 100%;
    width: 143%;
    position: absolute;
    right: -20%;
    max-width: 143%;
}
.portfolio-section .text-center .title ,.lasted-section .text-center .title {
    color: #ffffff;
    display: none;
}
.services-section .text-center .title  {
    color: #313131;
    display: none;
}
@media screen and (max-width: 500px) {

    .content-lasted {
        width: 85vw;
        height: 85vw;
        margin: 100px auto;
        text-align: center;
        align-items: center;
    }
    .services .grid .text-item {
        bottom: -56% !important;
        width: 100%;
        min-width: 200px;
        left: -17% !important;
        z-index: 10;
        position: relative;
        opacity: 1 !important;
    }
    .services .section-title .subtitle {
        width: 72% !important;
       
    }
    .services .grid:nth-child(4) .text-item {
        top: -42% !important;
       
    }
    .portfoliot .grid .text-item {
        bottom: -58% !important;
        left: -20% !important;
        opacity: 1 !important;
    }
    .portfoliot .grid:nth-child(4) .text-item {
        top: 62%;
        
    }
    .portfolio-section .text-center  .title ,.lasted-section .text-center  .title , .services-section .text-center  .title  {
        display: block;
    }
}

