/**************************************
    Default Styles
***************************************/

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');


@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';


/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

.clock {
}

.nums {
 
}

.clock-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &:before {
    content: '';
    height: 8px;
    width: 8px;
    background: #36213E;
    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    transform: translate(-50%,-50%);
  }
}

.hand {
  position: absolute;
 
  &.hour {
  }
  &.min {
   
    border-radius: 1px;
  }
  &.second {
    transition: transform 200ms cubic-bezier(.4,2.08,.55,.44);
   
  }
}
.fullscreen {
    background-color: var(--color-dark) !important;
    min-height: 100vh;
    width: 95% !important;
    top: 21px;
}

.logo1 {
  width: 100%;
  overflow: visible;
  stroke: rgba(0,0,0,.4);
  fill: rgba(255, 255, 255, 0);
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.logo2 {
  width: 100%;
  margin: 0 auto;
  overflow: visible;
  stroke: rgba(255,255,255,.8);
  fill: rgba(255, 255, 255, 0);
  stroke-width: 1.3;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.indicator{

}


.fondow {
  overflow: visible;
  stroke: rgba(0,0,0,.1);
  fill: rgba(255, 255, 255, 0);
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
}